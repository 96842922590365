import React from "react"
import { Link } from "gatsby"


const AboutPage = () => (
  <div>
    <header>
      <Link to="/">About</Link>
    </header>
    <main>
      <h1>About Air Engine</h1>
    </main>
  </div>
)

export default AboutPage